import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../../../../components/home/DashboardHeader/DashboardHeader";

export default function EditInformations() {
  const navigate = useNavigate();
  const { id } = useParams();


  const [inputs, setInputs] = useState({
    email: "",
    phone1: "",
    phone2: "",
    bonus1: "",
    bonus2: "",
    bonus3: "",
    instagram: "",
    facebook: "",
  });

  useEffect(() => {
    getOrder();
  }, []);

  function getOrder() {
    axios.get(`https://www.safqida.az/safqida/informations.php/${id}`)
      .then(response => {
        setInputs(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the order data!", error);
      });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.put(`https://www.safqida.az/safqida/informations.php/${id}/edit`, inputs)
      .then(response => {
        navigate("/dashboard/details");
      })
      .catch(error => {
        console.error("There was an error updating the order!", error);
      });
  };

  return (
    <>
    <DashboardHeader/>
    <div>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
            <tr>
              <th><label>Email: </label></th>
              <td><input value={inputs.email} type="text" name="email" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Phone1: </label></th>
              <td><input value={inputs.phone1} type="text" name="phone1" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Phone2: </label></th>
              <td><input value={inputs.phone2} type="text" name="phone2" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Bonus1: </label></th>
              <td><input value={inputs.bonus1} type="text" name="bonus1" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Bonus2: </label></th>
              <td><input value={inputs.bonus2} type="text" name="bonus2" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Bonus3: </label></th>
              <td><input value={inputs.bonus3} type="text" name="bonus3" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Instagram: </label></th>
              <td><input value={inputs.instagram} type="text" name="instagram" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Facebook: </label></th>
              <td><input value={inputs.facebook} type="text" name="facebook" onChange={handleChange} /></td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <button type="submit">Yadda saxla</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div></>
  );
}
