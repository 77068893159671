import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import Banner from "./Banner";


const Shop = () => { 
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const itemsPerPageFromBanner = () => {
    setItemsPerPage(itemsPerPage);
  };

  return (
  <>
    <div className="max-w-container mx-auto px-4">
  
      <Breadcrumbs title="Məhsullar" />
      
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
        {/* <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <ShopSideNav />
        </div> */}
        
        <div className="w-full mdl:w-[80%] lgl:w-[100%] h-full flex flex-col gap-10">
          
          {/* <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} /> */}
          <Banner/>
        
          {/* <Pagination /> */}
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div></>
  );
};

export default Shop;
