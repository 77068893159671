import axios from "axios";
import React, { useEffect, useState } from "react";
import "./orders.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../../../../components/home/DashboardHeader/DashboardHeader";

export default function DashboardInformations() {
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");

  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.safqida.az/safqida/informations.php/")
      .then(function (response) {
        setProducts(response.data);
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.safqida.az/safqida/informations.php/${id}/delete`)
      .then(function (response) {
        getProducts();
      });
  };
  
  return (
    <>
    <DashboardHeader/>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <div>
          <table>
            <thead>
              <tr>
              <th>Actions</th>
                <th>Email</th>
                <th>Nömrə 1</th>
                <th>Nömrə 2</th>
                <th>Bonus 1 </th>
                <th>Bonus 2</th>
                <th>Bonus 3</th>
                <th>İnstagram</th>
                <th>Facebook</th>
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    <Link
                      to={`/informations/edit/1`}
                      style={{ marginRight: "10px" }}
                    >
                      Yenilə
                    </Link>
                  </td>
                  <td>{user.email}</td>
                  <td>{user.phone1}</td>
                  <td>{user.phone2}</td>
                  <td>{user.bonus1}</td>
                  <td>{user.bonus2}</td>
                  <td>{user.bonus3}</td>
                  <td>{user.instagram}</td>
                  <td>{user.facebook}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
