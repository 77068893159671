import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Blog verisini sabit tutuyoruz (gerçek uygulamalarda API'den alabilirsiniz)

const BlogsDetail = () => {
  const [news, setNews] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const swiperRef = useRef(null); // Swiper referansı
  const [activeIndex, setActiveIndex] = useState(0); // Aktif slide index

  useEffect(() => {
    axios
      .get(`https://www.safqida.az/safqida/blogs.php/${id}`)
      .then((response) => {
        setNews(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching device:", error);
        setIsLoading(false);
      });
  }, [id]);

  // Eğer `blogs` verisi yüklendiyse fotoğrafları kontrol et
  const slides = [];
  if (news) {
    for (let i = 1; i <= 10; i++) {
      const imgUrl = news[`image${i}`];
      if (imgUrl) {
        slides.push({ img: `https://www.safqida.az/safqida/${imgUrl}` });
      }
    }
  }

  // Dinamik olarak 3 fotoğraf göstermek için hesaplama
  const getThumbnails = () => {
    const len = slides.length;
    return [
      slides[(activeIndex - 1 + len) % len], // Soldaki fotoğraf
      slides[activeIndex], // Aktif fotoğraf
      slides[(activeIndex + 1) % len], // Sağdaki fotoğraf
    ];
  };

  if (isLoading) {
    return <div>Loading...</div>; // Veriler yüklenirken bir loading göstergesi
  }

  return (
    <div className="flex max-w-container mx-auto justify-between pb-[130px]">
    <div className="mx-auto mt-10 w-[571px]">
      {/* Slider */}
      <Swiper
        ref={swiperRef}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)} // Aktif index güncellemesi
        modules={[Autoplay, Pagination, Navigation]}
        className="main-slider"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative">
              <img
                className="w-full h-[354px] rounded-xl"
                src={slide.img}
                alt={`Slide ${index + 1}`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Thumbnail'lar */}
      <div className="flex justify-center mt-5 space-x-3">
        {getThumbnails().map((slide, index) => (
          <div
            key={index}
            className={`cursor-pointer border-2 ${
              index === 1 ? "border-yellow-500" : "border-transparent "
            } rounded-lg overflow-hidden`}
            onClick={() =>
              swiperRef.current.swiper.slideTo(
                (activeIndex - 1 + index + slides.length) % slides.length
              )
            }
          >
            <img
              className="w-[183px] h-[129px] object-cover"
              src={slide.img}
              alt={`Thumbnail ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="mx-auto mt-10 w-[645px]">
      {/* Blog Başlık ve İçeriği */}
      <p className="text-[26px] font-medium">{news.title}</p>
      <p className="text-[14px] mt-[22px]">{news.text}</p>
    </div>
  </div>
  );
};

export default BlogsDetail;

