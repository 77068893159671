import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubscription = () => {
    if (emailInfo === "") {
      setErrMsg("Please provide an Email !");
    } else if (!emailValidation(emailInfo)) {
      setErrMsg("Please give a valid Email!");
    } else {
      setSubscription(true);
      setErrMsg("");
      setEmailInfo("");
    }
  };

  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.safqida.az/safqida/informations.php/")
      .then(function (response) {
        setProducts(response.data);
      });
  }
  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title="Saf Qida Haqqında Daha Çox" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%]">
            Saf Qida insanların sağlam qidalanmasına xidmət etmək məqsədi ilə 2012-ci ildə yaradılmış yerli şirkətdir.
            </p>
            <ul className="flex items-center gap-2">
              <a
                href={products[0]?.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaInstagram />
                </li>
              </a>
              <a
                href={products[0]?.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div>
          <FooterListTitle title="Məhsullar" />
          
          <ul className="flex flex-col gap-2">
          <Link to="/category/Arıçılıq məhsulları">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Arıçılıq məhsulları
            </li>
            </Link>
            <Link to="/category/Çərəzlər">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Çərəzlər
            </li>
            </Link>
            <Link to="/category/Sabunlar">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Sabunlar
            </li>
            </Link>
            <Link to="/category/Quru otlar">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Quru otlar
            </li>
            </Link>
            <Link to="/category/Süd məhsulları">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Süd məhsulları
            </li>
            </Link>
          </ul>
        </div>
        <div>
          <FooterListTitle title="Sürətli keçidlər" />
          <ul className="flex flex-col gap-2">
           <Link to="/">
           <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Əsas
            </li>
           </Link>
           <Link to="/mehsullar">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Məhsullar
            </li>
            </Link>
            <Link to="/about">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Haqqımızda
            </li>
            </Link>
            <Link to="/videolar">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Videolar
            </li>
            </Link>
            <Link to="/news">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Xəbərlər
            </li>
            </Link>
            <Link to="/blogs">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Bloqlar
            </li>
            </Link>
            <Link to="/elaqe">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Əlaqə
            </li>
            </Link>
          </ul>
        </div>
        <div className="col-span-2 flex flex-col items-center w-full px-4">
  <FooterListTitle title="Mesaj göndər" />
  <div className="w-full">
    <p className="text-center mb-4">
      Təklif və iradlarınızı bildirin
    </p>
    {subscription ? (
      <motion.p
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full text-center text-base font-titleFont font-semibold text-green-600"
      >
        Email uğurla göndərildi!
      </motion.p>
    ) : (
      <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
        <div className="flex flex-col w-full">
          <input
            onChange={(e) => setEmailInfo(e.target.value)}
            value={emailInfo}
            className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
            type="text"
            placeholder="Mesaj göndər ...*"
          />
          {errMsg && (
            <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
              {errMsg}
            </p>
          )}
        </div>
        <button
          onClick={handleSubscription}
          className="bg-white text-lightText w-[30%] h-10 hover:bg-black hover:text-white duration-300 text-base tracking-wide"
        >
          Göndər
        </button>
      </div>
    )}

    <Image
      className={`w-[80%] lg:w-[60%] mx-auto ${
        subscription ? "mt-2" : "mt-6"
      }`}
      imgSrc={paymentCard}
    />
        <div className="w-full justify-center items-center gap-8 mt-6">
      {/* Mail Icon */}
      <div className="flex items-center gap-2">
        <i className="fas fa-envelope text-2xl "></i>
        <span className="text-lg">{products[0]?.email}</span>
      </div>

      {/* Phone Icon */}
      <div className="flex items-center gap-2">
        <i className="fas fa-phone-alt text-2xl text-primeColor"></i>
        <span className="text-lg">{products[0]?.phone1}</span>
        <span className="text-lg ml-10">{products[0]?.phone2}</span>
      </div>
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default Footer;
