import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../../../components/home/DashboardHeader/DashboardHeader";

export default function DashboardNews() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    setLoading(true);
    axios
      .get("https://www.safqida.az/safqida/news.php/", {
        withCredentials: false,
      })
      .then(function (response) {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.safqida.az/safqida/news.php/${id}/delete`, {
        withCredentials: false,
      })
      .then(function () {
        getProducts();
      });
  };

  const updateUserLink = (id) => `/news/edit/${id}`;

  return (
    <>
      <DashboardHeader />
      <div className="container mx-auto p-8">
        <h2 className="text-3xl font-bold text-center mb-6">Xəbər Paneli</h2>
        {loading ? (
          <div className="flex justify-center items-center">
            <img
              style={{ width: "50px", height: "50px" }}
              src="/./loading.gif"
              alt="loading"
            />
          </div>
        ) : (
          <div className="overflow-x-auto shadow-lg rounded-lg">
            <table className="min-w-full bg-white rounded-lg shadow-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-600 text-left text-sm uppercase font-semibold">
                  <th className="px-6 py-3">Əməliyyatlar</th>
                  <th className="px-6 py-3">ID</th>
                  <th className="px-6 py-3">Başlıq</th>
                  <th className="px-6 py-3">Mətn</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr
                    key={product.id}
                    className="border-b hover:bg-gray-100 transition duration-300"
                  >
                    <td className="px-6 py-4 flex space-x-3">
                      <button
                        onClick={() => deleteUser(product.id)}
                        className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
                      >
                        Sil
                      </button>
                      <Link to={updateUserLink(product.id)}>
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition">
                          Yenilə
                        </button>
                      </Link>
                    </td>
                    <td className="px-6 py-4">{product.id}</td>
                    <td className="px-6 py-4">{product.title}</td>
                    <td className="px-6 py-4">{product.text}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
