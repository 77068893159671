import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Elaqe from "./pages/Elaqe/Elaqe";
import Home from "./pages/Home/Home";
import Videolar from "./pages/Videolar/Videolar";
import News from "./pages/News/News";
import Blogs from "./pages/Blogs/Blogs";
import Offer from "./pages/Offer/Offer";
import DashboardOffer from "./pages/admin/products/DashboardOffer/DashboardOffer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminPanel from './pages/admin/login/AdminPanel/AdminPanel'
import Dashboard from './pages/admin/products/Dashboard/Dashboard'
import CreateUser from './pages/admin/products/CreateUser/CreateUser'
import EditUser from './pages/admin/products/EditUser/EditUser'
import Protected from './pages/admin/login/Protected/Protected'
import Orders from "./pages/admin/orders/Orders/Orders";
import CreateOrder from "./pages/admin/orders/CreateOrder/CreateOrder";
import EditOrder from "./pages/admin/orders/EditOrder/EditOrder";
import BlogsDetail from "./pages/BlogsDetail/BlogsDetail";
import NewsDetail from "./pages/NewsDetail/NewsDetail";
import DashboardInformations from "./pages/admin/informations/DashboardInformations/DashboardInformations";
import DashboardNews from "./pages/admin/news/DashboardNews/DashboardNews";
import DashboardBlogs from "./pages/admin/blogs/DashboardBlogs/DashboardBlogs"; 
import EditBlog from "./pages/admin/blogs/EditBlog/EditBlog";
import EditNews from "./pages/admin/news/EditNews/EditNews";
import EditInformations from "./pages/admin/informations/EditInformations/EditInformations";
import CreateNews from "./pages/admin/news/CreateNews/CreateNews";
import CreateBlogs from "./pages/admin/blogs/CreateBlogs/CreateBlogs";
import DashboardCategories from "./pages/admin/products-detail/DashboardCategories/DashboardCategories";
import EditCategories from "./pages/admin/products-detail/EditCategories/EditCategories";

const Layout = () => {
  return ( 
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Header />
      {/* <HeaderBottom /> */}
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/mehsullar" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/elaqe" element={<Elaqe />}></Route>
        <Route path="/videolar" element={<Videolar />}></Route>

        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/news" element={<News />}></Route>
        <Route path="/blogs/:id" element={<BlogsDetail />}></Route>
        <Route path="/news/:id" element={<NewsDetail />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        {/* <Route path="/category/:category" element={<Offer />}></Route> */}
          <Route path="/category/:category" element={<Offer />}></Route>
        <Route path="/product/:id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/paymentgateway" element={<Payment />}></Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
          <Route path="/signin" element={<SignIn />}></Route>









      <Route path="/admin" element={<AdminPanel />} />

      <Route path="/images" element={<Protected Component={DashboardCategories} />} />
      <Route path="/images/:id/edit"  element={<Protected Component={EditCategories} />} />
 
      <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
      <Route path="dashboard/create"  element={<Protected Component={CreateUser} />} />
      <Route path="dashboard/:id/edit"  element={<Protected Component={EditUser} />} /> 
      <Route path="/dashboard/category/:category" element={<DashboardOffer />}></Route> 
 
      <Route path="/orders" element={<Protected Component={Orders} />} /> 
      <Route path="orders/create"  element={<Protected Component={CreateOrder} />} />
      <Route path="orders/:id/edit"  element={<Protected Component={EditOrder} />} />

      <Route path="/dashboard/details" element={<Protected Component={DashboardInformations} />} />
      <Route path="informations/edit/:id"  element={<Protected Component={EditInformations} />} />

      <Route path="/dashboard/news" element={<Protected Component={DashboardNews} />} />
      <Route path="news/create"  element={<Protected Component={CreateNews} />} />
      <Route path="news/edit/:id"  element={<Protected Component={EditNews} />} />

      <Route path="/dashboard/blogs" element={<Protected Component={DashboardBlogs} />} />
      <Route path="blogs/create"  element={<Protected Component={CreateBlogs} />} />
      <Route path="blogs/edit/:id"  element={<Protected Component={EditBlog} />} />
            </Route> 
    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
