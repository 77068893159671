import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DashboardHeader from "../../../../components/home/DashboardHeader/DashboardHeader";

export default function EditBlogs() {
  const { id } = useParams(); // URL'den ürün ID'sini al
  const navigate = useNavigate();
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`https://www.safqida.az/safqida/blogs.php/${id}`)
      .then((response) => {
        setDevice(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching device:", error);
        setLoading(false);
      });
  }, [id]);

  const handleUpdate = (e) => {
    e.preventDefault();
    axios
      .put(`https://www.safqida.az/safqida/blogs.php/${id}`, device)
      .then((response) => {
        if (response.data.status === 1) {
          alert("Bloq güncəlləndi!");
          navigate("/dashboard/blogs");
        } else {
          alert("Güncelleme başarısız oldu.");
        }
      })
      .catch((error) => {
        console.error("Error updating device:", error);
      });
  };

  const handleChange = (e) => {
    setDevice({ ...device, [e.target.name]: e.target.value });
  };

  return (
    <>
      <DashboardHeader />
      <div className="container mx-auto p-8">
        {loading ? (
          <div className="flex justify-center items-center">
            <img style={{ width: "50px", height: "50px" }} src="/./loading.gif" alt="loading" />
          </div>
        ) : (
          <form onSubmit={handleUpdate} className="bg-white p-6 rounded-lg shadow-lg max-w-xl mx-auto">
            <h2 className="text-2xl font-semibold text-center mb-6">Bloq Güncəllə</h2>
            <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">Başlıq:</label>
              <input
                type="text"
                name="title"
                id="title"
                value={device?.title || ""}
                onChange={handleChange}
                className="w-full p-3 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="text" className="block text-sm font-medium text-gray-700">Mətn:</label>
              <textarea
                name="text"
                id="text"
                value={device?.text || ""}
                onChange={handleChange}
                className="w-full p-3 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows="6"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 px-4 bg-blue-600 text-white font-bold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Yenilə
            </button>
          </form>
        )}
      </div>
    </>
  );
}
