import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import axios from "axios";

const Blogs = () => {
  
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.safqida.az/safqida/blogs.php/", {
        withCredentials: false,
      })
      .then(function (response) {
        setNews(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error("Error fetching products:", error);
        setIsLoading(false);
      });
  }


  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Bloqlar" />
      <div className="pb-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {news.map((blog) => (
            <div
              key={blog.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={`https://www.safqida.az/safqida/${blog.image1}`}
                alt={blog.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-lg font-bold text-gray-800 mb-2">
                  {blog.title}
                </h2>
                <Link
                  to={`/blogs/${blog.id}`}
                  className="text-[#04724D] hover:underline"
                >
                  Daha çox
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;