import React, { useState } from 'react';
import axios from 'axios';
import DashboardHeader from '../../../../components/home/DashboardHeader/DashboardHeader';
import { useNavigate } from 'react-router-dom';

const CreateBlogs = () => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [images, setImages] = useState(Array(10).fill(null)); // 10 görsel için
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleImageChange = (index, file) => {
        const newImages = [...images];
        newImages[index] = file;
        setImages(newImages);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('text', text);

        images.forEach((image, index) => {
            if (image) {
                formData.append(`image${index + 1}`, image);
            }
        });

        try {
            const response = await axios.post('https://www.safqida.az/safqida/blogs.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage(response.data.message);
            navigate("/dashboard/blogs");
        } catch (error) {
            console.error('Error uploading:', error);
            setMessage('Error uploading data');
        }
    };

    return (
        <>
            <DashboardHeader />
            <div className="container mx-auto p-8">
                <h2 className="text-3xl font-bold text-center mb-6">Yeni Bloq Yarat</h2>
                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="title">Başlıq</label>
                        <input
                            id="title"
                            type="text"
                            placeholder="Başlıq"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="text">Mətn</label>
                        <textarea
                            id="text"
                            placeholder="Mətn"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            rows="4"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        ></textarea>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                        {[...Array(10)].map((_, i) => (
                            <div key={i}>
                                <label className="block text-gray-700 font-semibold mb-1">
                                    Şəkil {i + 1}
                                </label>
                                <input
                                    type="file"
                                    onChange={(e) => handleImageChange(i, e.target.files[0])}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
                        >
                            Bloq əlavə et
                        </button>
                    </div>
                </form>
                {message && (
                    <p className={`mt-4 text-center ${message.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
                        {message}
                    </p>
                )}
            </div>
        </>
    );
};

export default CreateBlogs;
