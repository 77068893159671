import axios from "axios";
import React, { useEffect, useState } from "react";
import "./orders.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../../../../components/home/DashboardHeader/DashboardHeader";

export default function Orders() {
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.safqida.az/safqida/orders.php/")
      .then(function (response) {
        setProducts(response.data);
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.safqida.az/safqida/orders.php/${id}/delete`)
      .then(function (response) {
        getProducts();
      });
  };
  return (
    <>
    <DashboardHeader/>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <div>
          <table>
            <thead>
              <tr>
              <th>Actions</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Soyad</th>
                <th>Ölkə</th>
                <th>Ünvan</th>
                <th>Telefon</th>
                <th>Email</th>
                <th>Qeyd</th>
                <th>Products</th>
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    <Link
                      to={`/orders/${user.id}/edit`}
                      style={{ marginRight: "10px" }}
                    >
                      Yenilə
                    </Link>
                    <button onClick={() => deleteUser(user.id)}>Sil</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.surname}</td>
                  <td>{user.country}</td>
                  <td>{user.location}</td>
                  <td>{user.phone}</td>
                  <td>{user.email}</td>
                  <td>{user.extra}</td>
                  <td>{user.products}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
